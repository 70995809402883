<template>
  <div class="user-info">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item>编辑账户</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>账户信息</h3>
      <el-form label-width="120px">
        <el-form-item label="名字">
          <el-input placeholder="请输入名字"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱">
          <el-input placeholder="请输入电子邮箱"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <div style="display: flex;justify-content: space-between">
          <el-button>返回</el-button>
          <el-button>提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'userInfo'
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
.user-info {
  @include body;
}
</style>
